import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

import styles from './Step.module.scss';
import { useStep } from 'hooks/useStep';

type StepProps = {
  id: string;
};

export function Step(props: StepProps) {
  const { id } = props;
  const { step, handleClick } = useStep(id);

  if (!step) {
    return null;
  }

  const { isComplete, steps, text } = step;

  return (
    <li onClick={handleClick} className={styles.step}>
      <span
        className={classNames({
          'text-muted text-decoration-line-through': isComplete
        })}
      >
        <FontAwesomeIcon
          icon={Boolean(isComplete) ? faCheckSquare : faSquare}
          listItem
          fixedWidth
          style={{ top: '1px' }}
        />

        <ReactMarkdown>{text}</ReactMarkdown>
      </span>

      {steps && steps.length > 0 ? (
        <ul className="fa-ul">
          {steps.map((substepId) => (
            <Step key={substepId} id={substepId} />
          ))}
        </ul>
      ) : null}
    </li>
  );
}
