import { baseUrl } from 'api';

export async function fetchProviderBySlug(slug: string) {
  const res = await fetch(`${baseUrl}/providers/${slug}`);
  return await res.json();
}

export async function fetchAllProviders() {
  const res = await fetch(`${baseUrl}/providers`);
  return await res.json();
}
