import qs from 'qs';
import { baseUrl, defaultQuery } from 'api';

export async function fetchStates() {
  const query = qs.stringify(defaultQuery);

  const res = await fetch(`${baseUrl}/states?${query}`);
  return await res.json();
}

export async function fetchStateProviders(id: number | string) {
  let res: any = {};
  if (id === "default" || id === "all") {
    // Fetch all providers to be displayed
    res = await fetch(`${baseUrl}/providers`);
    const data = await res.json();
    return {
      providers: data,
      id: "all"
    }
  } else {
    // Fetch only providers with specified state id
    res = await fetch(`${baseUrl}/states/${id}`);
  }
  return await res.json();
}
