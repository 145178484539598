import { useState, useEffect } from 'react';
import styles from '../../pages/providers/Modal.module.scss';
import { toInteger } from 'lodash';
import UserApi from '../../api/user';
import { useChecklist } from 'hooks/useChecklist';
import { useInjectionChecklist } from 'hooks/useInjectionChecklist';
import { Link } from 'react-router-dom';
import { rootPath } from 'pages';
import TextareaAutosize from 'react-textarea-autosize';
import { Alert, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Checklist } from 'components/Checklist';
import { InjectionChecklist } from 'components/Checklist/InjectionChecklist';
import { EmailForm } from 'forms/EmailForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faMobile, faPrint, faEnvelope, faScalpel, faSyringe } from '@fortawesome/pro-regular-svg-icons';
import { Helmet } from 'react-helmet';
import { ChecklistToggle } from 'components/Checklist/ChecklistToggle';
import TagManager from 'react-gtm-module';
import { ChecklistType } from 'store/entities';

type endpointPost = {
    patient: string,
    checkedSteps: string[],
    recipient: string,
    instructions?: string,
    stepsLeftUncheckedCount: number,
    subjectLine: string,
    isFusion: boolean
}

export function ProviderDetails(props: any) {
    // Retrieve all steps based on checklist number
    const checklistNumber = props.provider.checklist;
    const injectionChecklistNumber = props.provider.injectionchecklist;
    
    const { incompleteSteps } = useChecklist(checklistNumber);
    const { injectionsIncompleteSteps } = useInjectionChecklist(injectionChecklistNumber);

    const [patientNote, setPatientNote] = useState('');
    const [patientName, setPatientName] = useState('');

    const [showMobileModal, setShowMobileModal] = useState(false);   // toggle modal desktop
    const [showDesktopModal, setShowDesktopModal] = useState(false); // toggle modal mobile
    const [showEmailForm, setShowEmailForm] = useState(false);       // toggle email modal
    const [showEmailAlert, setShowEmailAlert] = useState(false);      // toggle email modal
    const [isLoading, setIsLoading] = useState(false);               // loading for emitting email
    const [checklistType, setChecklistType] = useState<ChecklistType>(props.selectedChecklistType);

    let countForEndpoint = 0;

    useEffect(() => {        
        let userApiSub: any;
        userApiSub = UserApi.stateChanged.subscribe((s) => {
            if (isLoading && s.emailSent) {
                trackEvent('emailSent');
                onCloseModal();
                setShowEmailAlert(true);
                window.scrollTo(0, 0);
                setTimeout(() => {
                    setShowEmailAlert(false);
                }, 3000);
            }
        });
        return () => { userApiSub.unsubscribe(); }
    });

    function receiveValues(checklistPayload: any) {
        setPatientName(checklistPayload.patientName);
        
        if (incompleteSteps) {
            // This value should ALWAYS be the same as the value shown at the top of the checklist for each provider
            countForEndpoint = incompleteSteps; // incompleteSteps (does NOT include substeps)
        }
        if (injectionsIncompleteSteps) {
            // This value should ALWAYS be the same as the value shown at the top of the checklist for each provider
            countForEndpoint = injectionsIncompleteSteps; // incompleteSteps (does NOT include substeps)
        }
    }

    function onCloseModal() {
        setShowEmailForm(false);
        setIsLoading(false);
    }

    function onSubmitEmail(recipient: any,) {
        if (!recipient) { return; }

        // Separate out the step- and substep- from the step (parent) and substep (child) id's for simplified endpoint handling
        let updatedSteps: any = [];
        
        if (checklistType === ChecklistType.Fusion) {
            if (props.completedSteps) {
                updatedSteps = [];
                props.completedSteps.forEach((step: string) => {
                    updatedSteps.push(toInteger(step?.split("-")[1]))
                });
            }
        }
        
        if (checklistType === ChecklistType.Injection) {
            if (props.injectionCompletedSteps) {
                updatedSteps = [];
                props.injectionCompletedSteps.forEach((step: string) => {
                    updatedSteps.push(toInteger(step?.split("-")[1]))
                });
            }
        }
        

        const objToReturn: endpointPost = {
            patient: patientName,
            checkedSteps: updatedSteps,
            recipient: recipient,
            instructions: patientNote,
            stepsLeftUncheckedCount: countForEndpoint,
            subjectLine: checklistType === ChecklistType.Fusion ? "Your Insurance Criteria for Minimally Invasive SI Joint Fusion" : "Your Insurance Criteria for SI Joint Injections",
            isFusion: checklistType === ChecklistType.Fusion
        }
        setIsLoading(true);
        UserApi.sendEmail(props.provider ? props.provider.slug : '', objToReturn);
        return objToReturn;
    }

    function trackEvent(_e: any) {
        let _usr = UserApi.getLocalUser();
        let _data = {
            event: 'event',
            userId: _usr ? _usr.user.username : null,
            eventProps: {
                category: 'provider',
                action: _e,
                label: props.provider ? props.provider.slug : '',
            }
        };
        TagManager.dataLayer({ dataLayer: _data });
    }
    
    function toggleChecklist() {
        if (checklistType === ChecklistType.Fusion) {
            setChecklistType(ChecklistType.Injection)
        } else {
            setChecklistType(ChecklistType.Fusion)
        }
    }

    if (!props.provider) { return null; }

    return (
        <>
            <Helmet>
                <title>Interactive Payor Checklist for {`${props.provider.policyName}`}</title>
            </Helmet>

            {showEmailAlert &&
                <Alert color="success" >
                    Email successfully sent!
                </Alert>
            }

            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-6">
                    {props.provider.injectionchecklist &&
                        <ChecklistToggle selectedChecklist={checklistType} updateChecklistType={() => toggleChecklist()} />
                    }
                    
                    {checklistType === ChecklistType.Fusion && 
                        <h1 className="mb-4">
                            <FontAwesomeIcon icon={faScalpel} size="xs" />
                            &nbsp;SI Joint Fusion (MIS)
                        </h1>
                    }
                    {checklistType === ChecklistType.Injection && 
                        <h1 className="mb-4">
                            <FontAwesomeIcon icon={faSyringe} size="xs" />
                            &nbsp;SI Joint Injections
                        </h1>
                    }
                        
                    <p className="d-print-none">
                        <Link to={rootPath()} className="btn btn-outline-primary">Edit State &amp; Insurance Provider</Link>
                    </p>

                    <p className="mb-0">Medical necessity documentation requirements:</p>

                    <h2>{props.provider.name}</h2>

                    {checklistType === ChecklistType.Fusion &&
                        <Checklist checklistId={props.provider.checklist} policyName={props.provider.policyName} sendValues={receiveValues} patientName={patientName} />
                    }
                    
                    {checklistType === ChecklistType.Injection &&
                        <InjectionChecklist checklistId={props.provider.injectionchecklist} policyName={props.provider.InjectionPolicyName || " SI Joint Injections."} sendValues={receiveValues} patientName={patientName} />
                    }

                    <div className="mb-4">
                        <label htmlFor="notes" className="form-label">
                            Patient Notes
                        </label>

                        <TextareaAutosize
                            placeholder="Add patient notes&hellip;"
                            className="form-control"
                            id="notes"
                            minRows={8}
                            onBlur={(e) => {
                                setPatientNote(e.currentTarget.value);
                            }} />
                    </div>
                    <div className="d-print-none text-center my-4">
                        <button
                            onClick={() => { trackEvent('emailDialog'); setShowEmailForm(true); }}
                            className="w-50 btn btn-primary rounded-pill text-uppercase mx-auto"
                        >
                            <FontAwesomeIcon icon={faEnvelope} />
                            &nbsp;Email
                        </button>
                    </div>
                    <div className="d-print-none text-center my-3">
                        <button
                            onClick={() => { trackEvent('printDialog'); window.print(); }}
                            className="w-50 btn btn-primary rounded-pill text-uppercase mx-auto"
                        >
                            <FontAwesomeIcon icon={faPrint} />
                            &nbsp;Print
                        </button>
                    </div>

                    {/* Desktop and Mobile print instruction buttons */}
                    <div style={{textAlign: "center"}} className="align-items-center d-print-none">Instructions for printing on &nbsp;<br /><br/>
                        <Button color="primary" onClick={() => { trackEvent('desktopDialog'); setShowDesktopModal(true); }}>
                            <FontAwesomeIcon icon={faDesktop} />
                            &nbsp;Desktop
                        </Button>
                        &nbsp;or&nbsp;
                        <Button color="primary" onClick={() => { trackEvent('mobileDialog'); setShowMobileModal(true); }}>
                            <FontAwesomeIcon icon={faMobile} />
                            &nbsp;Mobile
                        </Button>
                    </div>

                </div>
            </div >

            {/* Modal handling */}
            <div>
                {/* Email Modal */}
                <Modal
                    size="md"
                    centered
                    scrollable
                    fade={false}
                    isOpen={showEmailForm}
                    toggle={() => { setShowEmailForm(false); }}>
                    <ModalBody>
                        <div className={styles.emailModal}>
                            <h2>Interactive Payor Checklist for {`${props.provider.name}`}</h2>
                        </div>
                        <EmailForm
                            onCloseModal={onCloseModal}
                            onSubmit={onSubmitEmail}
                            isLoading={isLoading}
                            checklistType={checklistType} />
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            </div>
            <div>
                {/* Desktop Print Instructions Modal */}
                <Modal
                    fullscreen="lg"
                    size="lg"
                    centered
                    scrollable
                    fade={false}
                    isOpen={showDesktopModal}
                    toggle={() => { setShowDesktopModal(false); }}
                    contentClassName="modal-open">
                    <ModalBody>
                        <div className={styles.modal}>
                            <div className="container">
                                <h1>How to Print to PDF</h1>
                                <h2>Desktop</h2>
                                <h4>On the checklist screen for the selected payor, make any changes or updates, then scroll to bottom</h4>
                                <img src="/resources/desktop/01.jpg" className="img-fluid" alt="Screenshot displaying webpage" />
                                <h4>At the bottom, click the green “PRINT” button</h4>
                                <img src="/resources/desktop/02.jpg" className="img-fluid" alt="Screenshot displaying webpage" />
                                <h4>Change Destination to “Save as PDF,” then click “Save”</h4>
                                <img src="/resources/desktop/03.jpg" className="img-fluid" alt="Screenshot displaying webpage" />
                                <h4>Select destination folder for PDF, then attach to any email</h4>
                                <img src="/resources/desktop/04.jpg" className="img-fluid" alt="Screenshot displaying webpage" />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => setShowDesktopModal(false)}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* Mobile Print Instructions Modal */}
                <Modal
                    fullscreen="sm"
                    size="sm"
                    fade={false}
                    centered
                    scrollable
                    isOpen={showMobileModal}
                    toggle={() => setShowMobileModal(false)}
                    contentClassName="modal-open">
                    <ModalBody>
                        <div className={styles.modal}>
                            <div className="container">
                                <h1>How to Print to PDF</h1>
                                <h2>iPhone</h2>
                                <h4>On the checklist screen for the selected payor, make any changes or updates, then scroll to bottom</h4>
                                <img src="/resources/mobile/01.jpg" className="img-fluid" alt="Screenshot displaying webpage" />
                                <h4>At the bottom, click the green “PRINT” button</h4>
                                <img src="/resources/mobile/02.jpg" className="img-fluid" alt="Screenshot displaying webpage" />
                                <h4>Hold down for 2 seconds on the image of the page, then let go
                                    (do NOT hit the blue “Print” button)</h4>
                                <img src="/resources/mobile/03.jpg" className="img-fluid" alt="Screenshot displaying webpage" />
                                <h4>On the larger image, give a quick tap (one time)</h4>
                                <img src="/resources/mobile/04.jpg" className="img-fluid" alt="Screenshot displaying webpage" />
                                <h4>Hit the blue “Send” icon in the top right-hand corner of screen</h4>
                                <img src="/resources/mobile/05.jpg" className="img-fluid" alt="Screenshot displaying webpage" />
                                <h4>Copy, email, or text as desired!</h4>
                                <img src="/resources/mobile/06.jpg" className="img-fluid" alt="Screenshot displaying webpage" />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => setShowMobileModal(false)}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </div >
        </>
    );
}