import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { providerPath } from 'pages/providers/slug';
import { useInput } from 'hooks/useInput';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useStateByIdQuery, useStatesQuery } from 'hooks/useStates';
import sortBy from 'lodash/sortBy';

export function ProviderForm() {
  // Pass 'default' so all providers are exposed on nav to Form after login
  const [savedState, setSavedState] = useLocalStorage<string>('savedState', 'default');
  const [shouldSaveState, setShouldSaveState] = useState(false);
  const history = useHistory();
  const providerInput = useInput('');
  const stateInput = useInput('');
  const states = useStatesQuery();

  const { value: state, setValue: setState, handleChange: handleStateChange } = stateInput;

  useEffect(() => {
    setShouldSaveState(savedState !== '');
  }, [savedState]);

  useEffect(() => {
    setState(savedState);
  }, [savedState, setState]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setSavedState(shouldSaveState ? state : '');

    history.push(providerPath(providerInput.value));
  }

  return (
    <form className="form-login" onSubmit={handleSubmit}>
      <select
        disabled={states.isLoading}
        className="form-select mb-5"
        onChange={(event) => {
          handleStateChange(event);
          providerInput.setValue('');
        }}
        value={state}
      >
        {/* Value on login */}
        <option value="default">{states.isLoading ? `Loading...` : `Select a State...`}</option>
        {/* Value if user does not want to specify a state */}
        <option value="all">{`All States`}</option>

        {states?.data?.map((state) => (
          <option key={state.id} value={state.id}>
            {state.name}
          </option>
        ))}
      </select>

      <StateProvidersSelect {...providerInput} stateId={state} />

      <div className="mb-4 form-check">
        <input
          checked={shouldSaveState}
          type="checkbox"
          className="form-check-input"
          id="remember-state"
          onChange={(e) => setShouldSaveState(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="remember-state">
          Remember my state
        </label>
      </div>

      <button
        className="w-100 btn btn-primary rounded-pill text-uppercase"
        disabled={!providerInput.value}
        type="submit"
      >
        Go
      </button>
    </form>
  );
}

type StateProvidersSelectProps = {
  stateId: string;
} & ReturnType<typeof useInput>;

function StateProvidersSelect(props: StateProvidersSelectProps) {
  const { handleChange, stateId, value } = props;
  // Can sometimes be "all" - otherwise is state ID
  const { data, isLoading } = useStateByIdQuery(stateId);
  const providers = sortBy(data?.providers, [(provider) => provider.name.toLowerCase()], ['name']);

  return (
    <select className="form-select mb-4" disabled={!stateId || isLoading} onChange={handleChange} value={value}>
      <option value="">{isLoading ? 'Loading...' : 'Select an insurance provider'}</option>

      {providers.map((provider) => (
        <option key={provider.slug} value={provider.slug}>
          {provider.name}
        </option>
      ))}
    </select>
  );
}
